import { YachtsFeatured } from '../../components';
import { PopularDestinations } from '../../components';


export default function home() {
    return (
      <>
      <main id="content">
        {/* ========== HERO ========== */}
        <div className="hero-block hero-v7 bg-img-hero-bottom gradient-overlay-half-blue-gradient text-center z-index-2" style={{backgroundImage: 'url(./images/hero-sailboat.jpg)'}}>
          <div className="container space-2 space-top-xl-3">
            {/* Info */}
            <div className="py-wd-10 pb-5">
              <h1 className="font-size-60 font-size-xs-30 text-white font-weight-bold">Your boat holidays start here</h1>
              <p className="font-size-20 font-weight-normal text-white">Rent a boat from the freindliest yacht charter company out there</p>
            </div>
            {/* End Info */}
            <div className="space-top-lg-2 space-top-xl-3">
              {/* Search Jobs Form */}
              <div className="card border-0">
                <div className="card-body">
                  <form className="js-validate">
                    <div className="row d-block nav-select d-lg-flex mb-lg-3 px-lg-3 px-2">
                      <div className="col-sm-12 col-lg-3dot7 mb-4 mb-lg-0 ">
                        {/* Input */}
                        <span className="d-block text-gray-1  font-weight-normal mb-0 text-left">Sailing area</span>
                        <select className="js-select selectpicker dropdown-select tab-dropdown col-12 pl-0 flaticon-pin-1 d-flex align-items-center text-primary font-weight-semi-bold" title="Where are you going?" data-style data-live-search="true" data-searchbox-classes="input-group-sm">
                          <option className="border-bottom border-color-1" value="project1" data-content="">
                            London, United Kingdom
                          </option>
                          <option className="border-bottom border-color-1" value="project2" data-content="">
                            New York, United States
                          </option>
                          <option className="border-bottom border-color-1" value="project3" data-content="">
                            New South Wales, Australia
                          </option>
                          <option className="border-bottom border-color-1" value="project4" data-content="">
                            Istanbul, Turkey
                          </option>
                          <option className value="project4" data-content="">
                            Reykjavík, Iceland
                          </option>
                        </select>
                        {/* End Input */}
                      </div>
                      <div className="col-sm-12 col-lg-3dot6 mb-4 mb-lg-0 ">
                        {/* Input */}
                        <span className="d-block text-gray-1 font-weight-normal mb-0 text-left">Dates</span>
                        <div className="js-focus-state">
                          <div className="border-bottom border-width-2 border-color-1">
                            <div id="datepickerWrapperFrom" className="u-datepicker input-group">
                              <div className="input-group-prepend">
                                <span className="d-flex align-items-center mr-2">
                                  <i className="flaticon-calendar text-primary font-weight-semi-bold" />
                                </span>
                              </div>
                              <input
                                className="js-range-datepicker font-size-lg-16 shadow-none font-weight-bold form-control hero-form bg-transparent  border-0"
                                type="date"
                                data-rp-wrapper="#datepickerWrapperFrom"
                                data-rp-type="range"
                                data-rp-date-format="M d / Y"
                                data-rp-default-date="[&quot;Jul 7 / 2020&quot;, &quot;Aug 25 / 2020&quot;]"
                              />
                            </div>
                            {/* End Datepicker */}
                          </div>
                        </div>
                        {/* End Input */}
                      </div>
                      <div className="col-sm-12 col-lg-2dot8 mb-4 mb-lg-0">
                        {/* Input */}
                        <span className="d-block text-gray-1 font-weight-normal mb-0 text-left">Boat Type</span>
                        <div className="js-focus-state">
                          <div className="d-flex border-bottom border-width-2 border-color-1">
                            <i className="flaticon-backpack d-flex align-items-center mr-2 text-primary font-weight-semi-bold" />
                            <select className="js-select selectpicker dropdown-select">
                              <option value="2 Rooms - 3 Guests" selected>Sailboat</option>
                              <option value="2 Rooms - 3 Guests">Catamaran</option>
                              <option value="2 Rooms - 3 Guests">Motorboat</option>
                            </select>
                          </div>
                        </div>
                        {/* End Input */}
                      </div>
                      <div className="col-sm-12 col-lg-1dot8 align-self-lg-end text-md-right">
                        <button type="submit" className="btn btn-primary text-white font-weight-semi-bold mb-xl-0 mb-lg-1 transition-3d-hover"><i className="flaticon-magnifying-glass font-size-20 mr-2" />Search</button>
                      </div>
                    </div>
                    {/* End Checkbox */}
                  </form>
                </div>
              </div>
              {/* End Search Jobs Form */}
            </div>
          </div>
        </div>
        {/* ========== END HERO ========== */}
        <div className="category-block category-v3 border-bottom border-color-8">
          <div className="container space-1 mt-3 mb-4">
            <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5">
              <h2 className="section-title text-black font-size-30 font-weight-bold mb-0">Featured Boats</h2>
            </div>
            {/* Product Cards Feature with Ratings */}
            <div className="row">
              <YachtsFeatured />
            </div>
            {/* End Product Cards Feature with Ratings */}
          </div>
        </div>
      </main>
      </>
    )
}

// <div className="site-section">
//   <PopularDestinations />
// </div>
// <div className="py-5 bg-primary">
//   <div className="container">
//     <div className="row">
//       <div className="col-lg-7 mr-auto mb-4 mb-lg-0">
//         <h2 className="mb-3 mt-0 text-white">Let's get started. Create your account</h2>
//         <p className="mb-0 text-white">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
//       </div>
//       <div className="col-lg-4">
//         <p className="mb-0"><a href="" className="btn btn-outline-white text-white btn-md px-5 font-weight-bold btn-md-block">Sign Up</a></p>
//       </div>
//     </div>
//   </div>
// </div>

// <nav aria-label="Page navigation">
//   <ul className="list-pagination-1 pagination border border-color-4 rounded-sm mb-5 mb-lg-0 overflow-auto overflow-xl-visible justify-content-md-center align-items-center py-2">
//     <li className="page-item">
//       <a className="page-link border-right rounded-0 text-gray-5" href="#" aria-label="Previous">
//         <i className="flaticon-left-direction-arrow font-size-10 font-weight-bold" />
//         <span className="sr-only">Previous</span>
//       </a>
//     </li>
//     <li className="page-item"><a className="page-link font-size-14" href="#">1</a></li>
//     <li className="page-item"><a className="page-link font-size-14 active" href="#">2</a></li>
//     <li className="page-item"><a className="page-link font-size-14" href="#">3</a></li>
//     <li className="page-item"><a className="page-link font-size-14" href="#">4</a></li>
//     <li className="page-item"><a className="page-link font-size-14" href="#">5</a></li>
//     <li className="page-item disabled"><a className="page-link font-size-14" href="#">...</a></li>
//     <li className="page-item"><a className="page-link font-size-14" href="#">66</a></li>
//     <li className="page-item"><a className="page-link font-size-14" href="#">67</a></li>
//     <li className="page-item">
//       <a className="page-link border-left rounded-0 text-gray-5" href="#" aria-label="Next">
//         <i className="flaticon-right-thin-chevron font-size-10 font-weight-bold" />
//         <span className="sr-only">Next</span>
//       </a>
//     </li>
//   </ul>
// </nav>

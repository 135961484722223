import React from 'react';

export default function Footer() {

    return (
      <footer className="footer border-top border-gray-33 pt-4">
        <div className="space-bottom-2 space-top-1">
          <div className="container">
            <div className="row justify-content-xl-between">
              <div className="col-12 col-lg-4 col-xl-3dot1 mb-6 mb-md-10 mb-xl-0">
                {/* Contacts */}
                <div className="d-md-flex d-lg-block">
                  <div className="ml-md-6 ml-lg-0">
                    <div className="mb-4">
                      <h4 className="h6 font-weight-bold mb-2 font-size-17">Travel Tome</h4>
                      <address className="pr-4">
                        <span className="mb-2 h6 font-weight-normal text-gray-1">
                          Veerenni 24, 10135, Tallinn, Estonia
                        </span>
                      </address>
                    </div>
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item mr-2">
                        <a className="btn btn-icon btn-social btn-bg-transparent" href="#">
                          <span className="fab fa-facebook-f btn-icon__inner" />
                        </a>
                      </li>
                      <li className="list-inline-item mr-2">
                        <a className="btn btn-icon btn-social btn-bg-transparent" href="#">
                          <span className="fab fa-twitter  btn-icon__inner" />
                        </a>
                      </li>
                      <li className="list-inline-item mr-2">
                        <a className="btn btn-icon btn-social btn-bg-transparent" href="#">
                          <span className="fab fa-instagram btn-icon__inner" />
                        </a>
                      </li>
                      <li className="list-inline-item mr-2">
                        <a className="btn btn-icon btn-social btn-bg-transparent" href="#">
                          <span className="fab fa-linkedin-in btn-icon__inner" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Contacts */}
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xl-1dot8 mb-6 mb-md-10 mb-xl-0">
                <h4 className="h6 font-weight-bold mb-2 mb-xl-4">Company</h4>
                {/* List Group */}
                <ul className="list-group list-group-flush list-group-borderless mb-0">
                  <li><a className="text-decoration-on-hover list-group-item list-group-item-action" href="../others/about.html">About us</a></li>
                  <li><a className="text-decoration-on-hover list-group-item list-group-item-action" href="../others/contact.html">Careers</a></li>
                  <li><a className="list-group-item list-group-item-action text-decoration-on-hover" href="../others/terms-conditions.html">Terms of Use</a></li>
                  <li><a className="list-group-item list-group-item-action text-decoration-on-hover" href="../others/terms-conditions.html">Privacy Statement</a></li>
                  <li><a className="list-group-item list-group-item-action text-decoration-on-hover" href="../others/terms-conditions.html">Give Us Feedbacks</a></li>
                </ul>
                {/* End List Group */}
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xl-1dot8 mb-6 mb-md-0">
                <h4 className="h6 font-weight-bold mb-2 mb-xl-4">Support</h4>
                {/* List Group */}
                <ul className="list-group list-group-flush list-group-borderless mb-0">
                  <li>
                    <a className="list-group-item list-group-item-action text-decoration-on-hover" href="../others/terms-conditions.html">Account</a>
                  </li>
                  <li>
                    <a className="list-group-item list-group-item-action text-decoration-on-hover" href="../others/terms-conditions.html">Legal</a>
                  </li>
                  <li>
                    <a className="list-group-item list-group-item-action text-decoration-on-hover" href="../others/contact.html">Contact</a>
                  </li>
                  <li>
                    <a className="list-group-item list-group-item-action text-decoration-on-hover" href="../others/terms-conditions.html">Affiliate Program</a>
                  </li>
                  <li>
                    <a className="list-group-item list-group-item-action text-decoration-on-hover" href="../others/terms-conditions.html">Privacy Policy</a>
                  </li>
                </ul>
                {/* End List Group */}
              </div>
              <div className="col-12 col-md-6 col-lg col-xl-3dot1">
                <div className="mb-4 mb-xl-2">
                  <h4 className="h6 font-weight-bold mb-2 mb-xl-4">Mailing List</h4>
                  <p className="m-0 text-gray-1">Sign up for our mailing list to get latest updates and offers.</p>
                </div>
                <form className="js-validate js-focus-state js-form-message" noValidate="novalidate">
                  <label className="sr-only text-gray-1" htmlFor="subscribeSrEmailExample1">Your Email</label>
                  <div className="input-group">
                    <input type="email" className="form-control height-54 font-size-14 border-radius-3 border-width-2 border-color-8" name="email" id="subscribeSrEmailExample1" placeholder="Your Email" aria-label="Your email address" aria-describedby="subscribeButtonExample3" required data-msg="Please enter a valid email address." data-error-class="u-has-error" data-success-class="u-has-success" />
                    <div className="input-group-append ml-3">
                      <button className="btn btn-sea-green border-radius-3 height-54 min-width-112 font-size-14" type="submit" id="subscribeButtonExample3">Subscribes</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="space-1">
          <div className="container">
            <div className="d-lg-flex d-md-block justify-content-between align-items-center">
              {/* Copyright */}
              <p className="text-muted mb-3 mb-lg-0 text-gray-1">© 2020 Travel Tome. All rights reserved</p>
              {/* End Copyright */}
              {/* Social Networks */}
              <ul className="list-inline mb-0">
                <li className="list-inline-item  pb-3 pb-md-0">
                  <a className="list-group-item-action text-decoration-on-hover pr-3" href="../home/index.html">Home</a>
                </li>
                <li className="list-inline-item  pb-3 pb-md-0">
                  <a className="list-group-item-action text-decoration-on-hover pr-3" href="../home/home-v9.html">Yacht</a>
                </li>
                <li className="list-inline-item">
                  <a className="list-group-item-action text-decoration-on-hover" href="../others/destination.html">Pages</a>
                </li>
              </ul>
              {/* End Social Networks */}
            </div>
          </div>
        </div>
      </footer>
    )
}

import { YachtDetails } from '../../components';
import { YachtHero } from '../../components';

export default function Yacht(props) {

    return (
      <div className="container">
        <div className="row">
          <YachtDetails yachtId = {props.match.params.yachtId}/>
        </div>
      </div>
    )
}
// <>
//
// <YachtHero yachtId = {props.match.params.yachtId}/>
// <div className="site-section bg-light">
//   <div className="container">
//     <div className="row">
//       <div className="col-lg-1">
//       </div>
//       <div className="col-lg-10">
//         <YachtDetails yachtId = {props.match.params.yachtId}/>
//       </div>
//     </div>
//   </div>
// </div>
// </>

// <div className="col-lg-3 ml-auto">
//   <div className="mb-5">
//     <h3 className="h5 text-black mb-3">Filters</h3>
//     <form action="#" method="post">
//       <div className="form-group">
//         <div className="select-wrap">
//           <span className="icon"><span className="icon-keyboard_arrow_down" /></span>
//           <select className="form-control" name id>
//             <option value>All Categories</option>
//             <option value>Sailing Yacht</option>
//             <option value>Catamaran</option>
//             <option value>Motor Boat</option>
//           </select>
//         </div>
//       </div>
//       <div className="form-group">
//         {/* select-wrap, .wrap-icon */}
//         <div className="wrap-icon">
//           <span className="icon icon-room" />
//           <input type="text" placeholder="Location" className="form-control" />
//         </div>
//       </div>
//     </form>
//   </div>
//   <div className="mb-5">
//     <form action="https://preview.colorlib.com/theme/browse/listings.html#" method="post">
//       <div className="form-group">
//         <p>Radius around selected base</p>
//       </div>
//       <div className="form-group">
//         <input type="range" min={0} max={100} defaultValue={20} data-rangeslider style={{position: 'absolute', width: '1px', height: '1px', overflow: 'hidden', opacity: 0}} />
//         <div className="rangeslider rangeslider--horizontal" id="js-rangeslider-0">
//           <div className="rangeslider__fill" style={{width: '55.2px'}} />
//           <div className="rangeslider__handle" style={{left: '48.2px'}} />
//         </div>
//         <div className="range-output">20</div>
//       </div>
//     </form>
//   </div>
//   <div className="mb-5">
//     <form action="https://preview.colorlib.com/theme/browse/listings.html#" method="post">
//       <div className="form-group">
//         <ul className="list-unstyled">
//           <li>
//             <label htmlFor="option1">
//               <input type="checkbox" id="option1" />
//               &nbsp;Air Conditioning
//             </label>
//           </li>
//           <li>
//             <label htmlFor="option2">
//               <input type="checkbox" id="option2" />
//               &nbsp;Generator
//             </label>
//           </li>
//           <li>
//             <label htmlFor="option3">
//               <input type="checkbox" id="option3" />
//               &nbsp;Bow Thrusters
//             </label>
//           </li>
//           <li>
//             <label htmlFor="option4">
//               <input type="checkbox" id="option4" />
//               &nbsp;Fishing Gear
//             </label>
//           </li>
//         </ul>
//       </div>
//     </form>
//   </div>
// </div>

// <div className="d-block d-md-flex listing-horizontal">
//   <a href="https://preview.colorlib.com/theme/browse/listings.html#" className="img d-block" style={{backgroundImage: 'url(images/img_2.jpg)'}}>
//     <span className="category">Restaurants</span>
//   </a>
//   <div className="lh-content">
//     <a href="https://preview.colorlib.com/theme/browse/listings.html#" className="bookmark"><span className="icon-heart" /></a>
//     <h3><a href="https://preview.colorlib.com/theme/browse/listings.html#">Jones Grill &amp; Restaurants</a></h3>
//     <p>Don St, Brooklyn, New York</p>
//     <p>
//       <span className="icon-star text-warning" />
//       <span className="icon-star text-warning" />
//       <span className="icon-star text-warning" />
//       <span className="icon-star text-warning" />
//       <span className="icon-star text-secondary" />
//       <span>(492 Reviews)</span>
//     </p>
//   </div>
// </div>

//<div>
//   <div className="site-blocks-cover inner-page-cover overlay aos-init aos-animate" style={{backgroundImage: 'url("images/listingHero.jpg")', backgroundPosition: '50% -25px'}} data-aos="fade" data-stellar-background-ratio="0.5">
//     <div className="container">
//       <div className="row align-items-center justify-content-center text-center">
//         <div className="col-md-10 aos-init aos-animate" data-aos="fade-up" data-aos-delay={400}>
//           <div className="row justify-content-center">
//             <div className="col-md-8 text-center">
//               <h1>Listings</h1>
//               <p data-aos="fade-up" data-aos-delay={100} className="aos-init aos-animate">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cupiditate beatae quisquam perspiciatis adipisci ipsam quam.</p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
//   <div class="d-block d-md-flex listing-horizontal">
//     <a href="https://preview.colorlib.com/theme/browse/listings.html#" class="img d-block" style={{backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/travel-tome-dev.appspot.com/o/yachts%2F1432734302000_9364990334500231_1432732732826_Draco_-_Elan_431_640.jpg?alt=media'}}>
//       <span class="category">Hotels</span>
//     </a>
//     <div class="lh-content">
//       <a href="https://preview.colorlib.com/theme/browse/listings.html#" class="bookmark"><span class="icon-heart"></span></a>
//       <h3><a href="https://preview.colorlib.com/theme/browse/listings.html#">Luxe Hotel</a></h3>
//       <p>West Orange, New York</p>
//       <p>
//         <span class="icon-star text-warning"></span>
//         <span class="icon-star text-warning"></span>
//         <span class="icon-star text-warning"></span>
//         <span class="icon-star text-warning"></span>
//         <span class="icon-star text-secondary"></span>
//       <span>(492 Reviews)</span>
//       </p>
//     </div>
//   </div>
// </div>

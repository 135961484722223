import firebase from "./firebase";

const db = firebase.collection("/yachts");

class YachtsDataService {
  getAll() {
    return db;
  }

  getById(id) {
    return db.doc(id);
  }

  // create(yacht) {
  //   return db.add(tutorial);
  // }
  //
  // update(id, value) {
  //   return db.doc(id).update(value);
  // }
  //
  // delete(id) {
  //   return db.doc(id).delete();
  // }
}

export default new YachtsDataService();

import React, { Component } from "react";
import YachtsDataService from "../../services/yachts.service";
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAltH } from '@fortawesome/free-solid-svg-icons'
import { faBed } from '@fortawesome/free-solid-svg-icons'
import { faShower } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import Carousel from 'nuka-carousel';

var createReactClass = require('create-react-class')

export default class YachtsList extends Component {

  constructor(props) {
    super(props);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveYacht = this.setActiveYacht.bind(this);
    this.onDataChange = this.onDataChange.bind(this);

    this.state = {
      yachts: [],
      currentYacht: null,
      currentIndex: -1,
    };

    this.unsubscribe = undefined;
  }

  componentDidMount() {
    this.unsubscribe = YachtsDataService.getAll().orderBy("name", "asc").onSnapshot(this.onDataChange);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onDataChange(items) {
    let yachts = [];

    items.forEach((item) => {
      let id = item.id;
      let data = item.data();
      let tmpImages = data.images;
      let tmpPrices = data.prices;
      let images = [];
      let prices = [];
      tmpImages.forEach(tmpImage => images.push(tmpImage.url));
      tmpPrices.forEach(tmpPrice => prices.push(Number(tmpPrice.price)));

      let minPrice = prices[0];
      prices.forEach((price) => {if (price < minPrice) {minPrice = price}});

      yachts.push({
        id: id,
        name: data.name,
        model: data.model,
        year: data.year,
        kind: data.kind,
        cabins: data.cabins.match(/\d+/)[0],
        berths: data.berths.match(/\d+/)[0],
        wc: data.wc.match(/\d+/)[0],
        length: data.length,
        images: images,
        minPrice: minPrice,
        prices: prices,
        homeBase: data.homeBase,
        location: data.homeBase.split('/')[0],
        coverImage: data.images[0].url

        // description: data.description,
        // published: data.published,
      });
    });

    this.setState({
      yachts: yachts,
    });
  }

  refreshList() {
    this.setState({
      currentYacht: null,
      currentIndex: -1,
    });
  }

  setActiveYacht(yacht, index) {
    this.setState({
      currentYacht: yacht,
      currentIndex: index,
    });
  }



  render() {
    const { yachts } = this.state;

    return (
      <>
        {yachts &&
          yachts.map((yacht, index) => (
            <div className="col-md-6 col-xl-3 mb-3 mb-md-4 pb-1">
              <div className="card transition-3d-hover shadow-hover-2 h-100">
                <div className="position-relative">
                    <a href={'./yacht/' + yacht.id} className="d-block gradient-overlay-half-bg-gradient-v5">
                      <img className="card-img-top" src={yacht.coverImage}/>
                    </a>
                  <div className="position-absolute top-0 right-0 pt-3 pr-3">
                    <button type="button" className="btn btn-sm btn-icon text-white rounded-circle" data-toggle="tooltip" data-placement="top" title data-original-title="Save for later">
                      <span className="flaticon-heart-1 font-size-20" />
                    </button>
                  </div>
                  <div className="position-absolute bottom-0 left-0 right-0">
                    <div className="px-3 pb-2">
                      <a href="../yacht/yacht-single-v1.html">
                        <span className="text-white font-weight-bold font-size-17">{yacht.name}</span>
                      </a>
                      <div className="text-white my-2">
                        <span className="mr-1 font-size-14">From</span>
                        <span className="font-weight-bold font-size-19">{yacht.minPrice} &euro;</span>
                        <span className="font-size-14"> / week</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body px-4 pt-3 pb-2 border-bottom">
                  <a href="../yacht/yacht-single-v1.html" className="d-block">
                    <div className="d-flex align-items-center font-size-14 text-gray-1">
                      <i className="icon flaticon-placeholder mr-2 font-size-20" /> {yacht.location}
                    </div>
                  </a>
                  <div className="my-2">
                    <div className="d-inline-flex align-items-center font-size-14 text-lh-1 text-primary">
                      <div className="green-lighter mr-2">
                        <small className="fas fa-star" />
                        <small className="fas fa-star" />
                        <small className="fas fa-star" />
                        <small className="fas fa-star" />
                        <small className="fas fa-star" />
                      </div>
                      <span className="text-secondary font-size-14 mt-1">48 Reviews</span>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3">
                  <div className="row">
                    <div className="col-6">
                      <ul className="list-unstyled mb-0">
                        <li className="media mb-2 text-gray-1 align-items-center">
                          <small className="mr-3">
                            <small className="flaticon-ruler font-size-16" />
                          </small>
                          <div className="media-body font-size-1">
                            {yacht.length} m
                          </div>
                        </li>
                        <li className="media mb-2 text-gray-1 align-items-center">
                          <small className="mr-3">
                            <small className="flaticon-user font-size-16" />
                          </small>
                          <div className="media-body font-size-1">
                            {yacht.berths}
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="col-6">
                      <ul className="list-unstyled mb-0">
                        <li className="media mb-2 text-gray-1 align-items-center">
                          <small className="mr-3">
                            <small className="flaticon-bathtub font-size-16" />
                          </small>
                          <div className="media-body font-size-1">
                            {yacht.wc}
                          </div>
                        </li>
                        <li className="media mb-2 text-gray-1 align-items-center">
                          <small className="mr-3">
                            <small className="flaticon-door font-size-16" />
                          </small>
                          <div className="media-body font-size-1">
                            {yacht.cabins}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        ))}
      </>
    );
  }
}
// <Carousel heightMode={'first'} renderBottomCenterControls={false}
//   renderCenterLeftControls={({ previousSlide }) => ( <div style={{padding: '10px'}}><FontAwesomeIcon icon={faChevronLeft} onClick={previousSlide} style={{cursor: "pointer", fontSize: '2rem'}} /></div> )}
//   renderCenterRightControls={({ nextSlide }) => ( <div style={{padding: '10px'}}><FontAwesomeIcon icon={faChevronRight} onClick={nextSlide} style={{cursor: "pointer", fontSize: '2rem'}}/></div> )}
// >
//   {yacht.images.map((image) => (<img className="card-img-top" src={image}/>))}
// </Carousel>


// <div className="d-block d-md-flex listing-horizontal" key={yacht.id}>
//   <Carousel heightMode={'first'} renderBottomCenterControls={false}
//     renderCenterLeftControls={({ previousSlide }) => ( <div style={{padding: '10px'}}><FontAwesomeIcon icon={faChevronLeft} onClick={previousSlide} style={{cursor: "pointer", fontSize: '2rem'}} /></div> )}
//     renderCenterRightControls={({ nextSlide }) => ( <div style={{padding: '10px'}}><FontAwesomeIcon icon={faChevronRight} onClick={nextSlide} style={{cursor: "pointer", fontSize: '2rem'}}/></div> )}
//   >
//     {yacht.images.map((image) => (<img src={image}/>))}
//   </Carousel>
//   <div className="lh-content">
//     <h3><Link to={{pathname: './yacht/' + yacht.id}} >{yacht.name} ({yacht.year})</Link></h3>
//     <p>{yacht.model}</p>
//     <p><FontAwesomeIcon icon={faArrowsAltH} /> {yacht.length} m</p>
//     <p><FontAwesomeIcon icon={faBed} /> {yacht.berths}&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faShower} /> {yacht.wc}</p>
//     <p><strong>Starting from {yacht.minPrice} &euro; per week</strong></p>
//     <input type="submit" className="btn text-white btn-primary" value="Book" style={{borderRadius: '30px', padding: '10px 30px'}}/>
//   </div>
// </div>

// <Carousel>
//   {yacht.images.map((image) => (<img src={image}/>))}
// </Carousel>

// renderCenterLeftControls={({ previousSlide }) => (
//   <button onClick={previousSlide}>
//     <FontAwesomeIcon icon={faChevronCircleLeft} />
//   </button>
// )}
// renderCenterRightControls={({ nextSlide }) => (
//   <button onClick={nextSlide}>
//     <FontAwesomeIcon icon={faChevronCircleRight} />
//   </button>
// )}


// <div className="list row">
//   <div className="col-md-6">
//     <ul className="list-group">
//       {tutorials &&
//         tutorials.map((tutorial, index) => (
//           <li
//             className={ "list-group-item " + (index === currentIndex ? "active" : "") }
//             onClick={() => this.setActiveTutorial(tutorial, index)}
//             key={index}
//           >
//             {tutorial.title}
//           </li>
//         ))}
//     </ul>
//   </div>
// </div>


// <div className="col-md-6">
//   {currentTutorial ? (
//     <Tutorial
//       tutorial={currentTutorial}
//       refreshList={this.refreshList}
//     />
//   ) : (
//     <div>
//       <br />
//       <p>Please click on a Tutorial...</p>
//     </div>
//   )}
// </div>

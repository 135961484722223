import { YachtsList } from '../../components';
import { PopularDestinations } from '../../components';


export default function Listing() {
    return (
      <>
      <main id="content" role="main" className="pt-6 pt-xl-10 mb-8">
        <div className="container">
          <div className="cars-list">
            {/* Shop-control-bar Title */}
            <div className="d-md-flex justify-content-md-between mb-md-5 align-items-center">
              <h3 className="font-size-21 font-weight-bold mb-0 text-center mb-3 mb-md-0">London: 3292 tours found</h3>
              <div className="d-md-flex">
                <select className="js-select selectpicker dropdown-select bootstrap-select__custom-nav w-100 w-md-auto my-1 mb-3 mb-md-0" data-style="btn-sm px-4 font-size-16 text-gray-1 d-flex align-items-center py-2">
                  <option value="one" selected>Sort by</option>
                  <option value="two">Sort by popularity</option>
                  <option value="three">Sort by average rating</option>
                  <option value="four">Sort by latest</option>
                  <option value="four">Sort by price: low to high</option>
                  <option value="four">Sort by price: high to low</option>
                </select>
                <div className="filters mb-3 mb-md-0 text-center">
                  {/* Account Sidebar Toggle Button */}
                  <a id="sidebarNavToggler" className="btn btn-sm btn-primary min-width-170 height-50 font-size-16 d-flex align-items-center justify-content-center" href="javascript:;" role="button" aria-controls="sidebarContent" aria-haspopup="true" aria-expanded="false" data-unfold-event="click" data-unfold-hide-on-scroll="false" data-unfold-target="#sidebarContent" data-unfold-type="css-animation" data-unfold-animation-in="fadeInRight" data-unfold-animation-out="fadeOutRight" data-unfold-duration={500}>
                    <i className="flaticon-filter mr-2 font-size-20" />
                    Show Filters
                  </a>
                  {/* End Account Sidebar Toggle Button */}
                  {/* Sidebar Navigation */}
                  <aside id="sidebarContent" className="u-sidebar" aria-labelledby="sidebarNavToggler">
                    <div className="u-sidebar__scroller">
                      <div className="u-sidebar__container">
                        <div className="u-header-sidebar__footer-offset">
                          {/* Toggle Button */}
                          <div className="d-flex align-items-center pt-4 px-7">
                            <button type="button" className="close ml-auto" aria-controls="sidebarContent" aria-haspopup="true" aria-expanded="false" data-unfold-event="click" data-unfold-hide-on-scroll="false" data-unfold-target="#sidebarContent" data-unfold-type="css-animation" data-unfold-animation-in="fadeInRight" data-unfold-animation-out="fadeOutRight" data-unfold-duration={500}>
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          {/* End Toggle Button */}
                          {/* Content */}
                          <div className="js-scrollbar u-sidebar__body">
                            <div className="u-sidebar__content u-header-sidebar__content">
                              <div className="pb-4 mb-2">
                                <div className="sidebar border border-radius-3">
                                  <div className="p-4 m-1">
                                    {/* Input */}
                                    <span className="d-block text-gray-1  font-weight-normal mb-0 text-left">Pick-up From</span>
                                    <div className="mb-4">
                                      <div className="input-group border-bottom border-width-2 border-color-1">
                                        <i className="flaticon-pin-1 d-flex align-items-center mr-2 text-primary font-weight-semi-bold font-size-22" />
                                        <input type="text" className="form-control font-size-lg-14 shadow-none hero-form font-weight-bold border-0 p-0" placeholder="London" aria-label="Keyword or title" aria-describedby="keywordInputAddon" />
                                      </div>
                                    </div>
                                    {/* End Input */}
                                    {/* Input */}
                                    <span className="d-block text-gray-1  font-weight-normal mb-0 text-left">Drop-of to</span>
                                    <div className="mb-4">
                                      <div className="input-group border-bottom border-width-2 border-color-1">
                                        <i className="flaticon-pin-1 d-flex align-items-center mr-2 text-primary font-weight-semi-bold font-size-22" />
                                        <input type="text" className="form-control font-size-lg-14 shadow-none hero-form font-weight-bold border-0 p-0" placeholder="New York" aria-label="Keyword or title" aria-describedby="keywordInputAddon" />
                                      </div>
                                    </div>
                                    {/* End Input */}
                                    {/* Input */}
                                    <span className="d-block text-gray-1 font-weight-normal mb-0 text-left">Pick Up Date</span>
                                    <div className="mb-4">
                                      <div className="border-bottom border-width-2 border-color-1">
                                        <div id="datepickerWrapperPick" className="u-datepicker input-group">
                                          <div className="input-group-prepend">
                                            <span className="d-flex align-items-center mr-2 font-size-21">
                                              <i className="flaticon-calendar text-primary font-weight-semi-bold" />
                                            </span>
                                          </div>
                                          <input className="js-range-datepicker font-size-lg-16 shadow-none font-weight-bold form-control hero-form bg-transparent border-0 flatpickr-input p-0" type="text" placeholder="July 7/2019" aria-label="July 7/2019" data-rp-wrapper="#datepickerWrapperPick" data-rp-date-format="M d /Y" />
                                        </div>
                                        {/* End Datepicker */}
                                      </div>
                                    </div>
                                    {/* End Input */}
                                    {/* Input */}
                                    <span className="d-block text-gray-1 font-weight-normal mb-0 text-left">Return Time</span>
                                    <div className="mb-4 pb-1">
                                      <div className="border-bottom border-width-2 border-color-1">
                                        <div id="datepickerWrapperReturn" className="u-datepicker input-group">
                                          <div className="input-group-prepend">
                                            <span className="d-flex align-items-center mr-2 font-size-21">
                                              <i className="flaticon-calendar text-primary font-weight-semi-bold" />
                                            </span>
                                          </div>
                                          <input className="js-range-datepicker font-size-lg-16 shadow-none font-weight-bold form-control hero-form bg-transparent border-0 flatpickr-input p-0" type="text" placeholder="Aug 25/2019" aria-label="Aug 25/2019" data-rp-wrapper="#datepickerWrapperReturn" data-rp-date-format="M d /Y" />
                                        </div>
                                        {/* End Datepicker */}
                                      </div>
                                    </div>
                                    {/* End Input */}
                                    <div className="text-center">
                                      <button type="submit" className="btn btn-primary height-60 w-100 mb-xl-0 mb-lg-1 transition-3d-hover"><i className="flaticon-magnifying-glass mr-2" />Search</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="pb-4 mb-2">
                                <a href="https://goo.gl/maps/kCVqYkjHX3XvoC4B9" className="d-block border">
                                  <img src="../../assets/img/map-markers/map.jpg" alt="" width="100%" />
                                </a>
                              </div>
                              {/* Checkboxes */}
                              <div className="sidenav border">
                                {/* Accordiaon */}
                                <div id="shopCartAccordion" className="accordion rounded shadow-none">
                                  <div className="border-0">
                                    <div className="card-collapse" id="shopCardHeadingOne">
                                      <h3 className="mb-0">
                                        <button type="button" className="btn btn-link btn-block card-btn py-2 px-5 text-lh-3 collapsed" data-toggle="collapse" data-target="#shopCardOne" aria-expanded="false" aria-controls="shopCardOne">
                                          <span className="row align-items-center">
                                            <span className="col-9">
                                              <span className="d-block font-size-lg-15 font-size-17 font-weight-bold text-dark">Price Range ($)</span>
                                            </span>
                                            <span className="col-3 text-right">
                                              <span className="card-btn-arrow">
                                                <span className="fas fa-chevron-down small" />
                                              </span>
                                            </span>
                                          </span>
                                        </button>
                                      </h3>
                                    </div>
                                    <div id="shopCardOne" className="collapse show" aria-labelledby="shopCardHeadingOne" data-parent="#shopCartAccordion">
                                      <div className="card-body pt-0 px-5">
                                        <div className="pb-3 mb-1 d-flex text-lh-1">
                                          <span>£</span>
                                          <span id="rangeSliderExample3MinResult" className />
                                          <span className="mx-0dot5"> — </span>
                                          <span>£</span>
                                          <span id="rangeSliderExample3MaxResult" className />
                                        </div>
                                        <input className="js-range-slider" type="text" data-extra-classes="u-range-slider height-35" data-type="double" data-grid="false" data-hide-from-to="true" data-min={0} data-max={3456} data-from={200} data-to={3456} data-prefix="$" data-result-min="#rangeSliderExample3MinResult" data-result-max="#rangeSliderExample3MaxResult" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div id="shopCategoryAccordion" className="accordion rounded-0 shadow-none border-top">
                                  <div className="border-0">
                                    <div className="card-collapse" id="shopCategoryHeadingOne">
                                      <h3 className="mb-0">
                                        <button type="button" className="btn btn-link btn-block card-btn py-2 px-5 text-lh-3 collapsed" data-toggle="collapse" data-target="#shopCategoryOne" aria-expanded="false" aria-controls="shopCategoryOne">
                                          <span className="row align-items-center">
                                            <span className="col-9">
                                              <span className="d-block font-size-lg-15 font-size-17 font-weight-bold text-dark">Categories</span>
                                            </span>
                                            <span className="col-3 text-right">
                                              <span className="card-btn-arrow">
                                                <span className="fas fa-chevron-down small" />
                                              </span>
                                            </span>
                                          </span>
                                        </button>
                                      </h3>
                                    </div>
                                    <div id="shopCategoryOne" className="collapse show" aria-labelledby="shopCategoryHeadingOne" data-parent="#shopCategoryAccordion">
                                      <div className="card-body pt-0 mt-1 px-5 text-left">
                                        {/* Checkboxes */}
                                        <div className="form-group font-size-14 text-lh-md text-secondary mb-3">
                                          <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="brandAdidas" />
                                            <label className="custom-control-label text-color-1" htmlFor="brandAdidas">Convertibles</label>
                                          </div>
                                        </div>
                                        <div className="form-group font-size-14 text-lh-md text-secondary mb-3">
                                          <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="brandNewBalance" />
                                            <label className="custom-control-label text-color-1" htmlFor="brandNewBalance">Coupes</label>
                                          </div>
                                        </div>
                                        <div className="form-group font-size-14 text-lh-md text-secondary mb-3">
                                          <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="brandNike" />
                                            <label className="custom-control-label text-color-1" htmlFor="brandNike">Sedan</label>
                                          </div>
                                        </div>
                                        <div className="form-group font-size-14 text-lh-md text-secondary mb-3">
                                          <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="brandFredPerry" />
                                            <label className="custom-control-label text-color-1" htmlFor="brandFredPerry">SUV</label>
                                          </div>
                                        </div>
                                        {/* End Checkboxes */}
                                        {/* View More - Collapse */}
                                        <div className="collapse" id="collapseBrand">
                                          <div className="form-group font-size-14 text-lh-md text-secondary mb-3">
                                            <div className="custom-control custom-checkbox">
                                              <input type="checkbox" className="custom-control-input" id="brandGucci" />
                                              <label className="custom-control-label text-color-1" htmlFor="brandGucci">Gucci</label>
                                            </div>
                                          </div>
                                          <div className="form-group font-size-14 text-lh-md text-secondary mb-3">
                                            <div className="custom-control custom-checkbox">
                                              <input type="checkbox" className="custom-control-input" id="brandMango" />
                                              <label className="custom-control-label text-color-1" htmlFor="brandMango">Mango</label>
                                            </div>
                                          </div>
                                        </div>
                                        {/* End View More - Collapse */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* End Content */}
                        </div>
                      </div>
                    </div>
                  </aside>
                  {/* End Sidebar Navigation */}
                </div>
              </div>
            </div>
            {/* End shop-control-bar Title */}
            {/* Product Cards Feature with Ratings */}
            <div className="row">
              <YachtsList />
            </div>
            {/* End Product Cards Feature with Ratings */}
            <div className="text-center text-md-left font-size-14 mb-3 text-lh-1">Showing 1–15</div>
          </div>
        </div>
      </main>
      </>
    )
}

// <div className="site-section">
//   <PopularDestinations />
// </div>
// <div className="py-5 bg-primary">
//   <div className="container">
//     <div className="row">
//       <div className="col-lg-7 mr-auto mb-4 mb-lg-0">
//         <h2 className="mb-3 mt-0 text-white">Let's get started. Create your account</h2>
//         <p className="mb-0 text-white">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
//       </div>
//       <div className="col-lg-4">
//         <p className="mb-0"><a href="" className="btn btn-outline-white text-white btn-md px-5 font-weight-bold btn-md-block">Sign Up</a></p>
//       </div>
//     </div>
//   </div>
// </div>

// <nav aria-label="Page navigation">
//   <ul className="list-pagination-1 pagination border border-color-4 rounded-sm mb-5 mb-lg-0 overflow-auto overflow-xl-visible justify-content-md-center align-items-center py-2">
//     <li className="page-item">
//       <a className="page-link border-right rounded-0 text-gray-5" href="#" aria-label="Previous">
//         <i className="flaticon-left-direction-arrow font-size-10 font-weight-bold" />
//         <span className="sr-only">Previous</span>
//       </a>
//     </li>
//     <li className="page-item"><a className="page-link font-size-14" href="#">1</a></li>
//     <li className="page-item"><a className="page-link font-size-14 active" href="#">2</a></li>
//     <li className="page-item"><a className="page-link font-size-14" href="#">3</a></li>
//     <li className="page-item"><a className="page-link font-size-14" href="#">4</a></li>
//     <li className="page-item"><a className="page-link font-size-14" href="#">5</a></li>
//     <li className="page-item disabled"><a className="page-link font-size-14" href="#">...</a></li>
//     <li className="page-item"><a className="page-link font-size-14" href="#">66</a></li>
//     <li className="page-item"><a className="page-link font-size-14" href="#">67</a></li>
//     <li className="page-item">
//       <a className="page-link border-left rounded-0 text-gray-5" href="#" aria-label="Next">
//         <i className="flaticon-right-thin-chevron font-size-10 font-weight-bold" />
//         <span className="sr-only">Next</span>
//       </a>
//     </li>
//   </ul>
// </nav>

import React, { Component } from "react";
import YachtsDataService from "../../services/yachts.service";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAltH } from '@fortawesome/free-solid-svg-icons'
import { faBed } from '@fortawesome/free-solid-svg-icons'
import { faShower } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import Carousel from 'nuka-carousel';

var createReactClass = require('create-react-class')

export default class YachtHero extends Component {

  constructor(props) {
    super(props);
    this.onDataChange = this.onDataChange.bind(this);

    this.state = {
      yachtId: props.yachtId,
      yacht: {images: []},
    };

    this.unsubscribe = undefined;
  }

  componentDidMount() {
    this.unsubscribe = YachtsDataService.getById(this.state.yachtId).onSnapshot(this.onDataChange);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onDataChange(item) {
    let yacht = {
      'heroImageUrl': item.data().images[0]['url'],
      'name': item.data().name,
      'model': item.data().model,
      'year': item.data().year,
      'homeBase': item.data().homeBase,
    };


    this.setState({
      yacht: yacht,
    });

    console.log(item.data())

  }

  render() {
    const { yacht } = this.state;

    return (
      <>
        <div className="site-blocks-cover inner-page-cover overlay aos-init aos-animate" style={{backgroundImage: `url(${yacht.heroImageUrl})`, backgroundPosition: '50% 50%'}} data-aos="fade" data-stellar-background-ratio="0.5">
          <div className="container">
            <div className="row align-items-center justify-content-center text-center">
              <div className="col-md-10 aos-init aos-animate" data-aos="fade-up" data-aos-delay={400}>
                <div className="row justify-content-center">
                  <div className="col-md-8 text-center">
                    <h1>{yacht.name}</h1>
                    <p data-aos="fade-up" data-aos-delay={100} className="aos-init aos-animate">{yacht.model}</p>
                    <p data-aos="fade-up" data-aos-delay={100} className="aos-init aos-animate">{yacht.homeBase}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

// <Carousel>
//   {yacht.images.map((image) => (<img src={image}/>))}
// </Carousel>

// renderCenterLeftControls={({ previousSlide }) => (
//   <button onClick={previousSlide}>
//     <FontAwesomeIcon icon={faChevronCircleLeft} />
//   </button>
// )}
// renderCenterRightControls={({ nextSlide }) => (
//   <button onClick={nextSlide}>
//     <FontAwesomeIcon icon={faChevronCircleRight} />
//   </button>
// )}


// <div className="list row">
//   <div className="col-md-6">
//     <ul className="list-group">
//       {tutorials &&
//         tutorials.map((tutorial, index) => (
//           <li
//             className={ "list-group-item " + (index === currentIndex ? "active" : "") }
//             onClick={() => this.setActiveTutorial(tutorial, index)}
//             key={index}
//           >
//             {tutorial.title}
//           </li>
//         ))}
//     </ul>
//   </div>
// </div>


// <div className="col-md-6">
//   {currentTutorial ? (
//     <Tutorial
//       tutorial={currentTutorial}
//       refreshList={this.refreshList}
//     />
//   ) : (
//     <div>
//       <br />
//       <p>Please click on a Tutorial...</p>
//     </div>
//   )}
// </div>

import React from 'react';
import { Menu } from '../components'
import { Footer } from '../components'
import Views from '../views'
import ScriptTag from 'react-script-tag';

export default function Main() {
    return (
      <div>
        <Menu />
        <Views />
        <Footer />
        <ScriptTag type="text/javascript" src="/assets/vendor/jquery-migrate/dist/jquery-migrate.min.js" />
        <ScriptTag type="text/javascript" src="/assets/vendor/popper.js/dist/umd/popper.min.js" />

        <ScriptTag type="text/javascript" src="/assets/vendor/flatpickr/dist/flatpickr.min.js" />

        <ScriptTag type="text/javascript" src="/assets/vendor/hs-megamenu/src/hs.megamenu.js" />
        <ScriptTag type="text/javascript" src="/assets/vendor/jquery-validation/dist/jquery.validate.min.js" />
        <ScriptTag type="text/javascript" src="/assets/vendor/bootstrap-select/dist/js/bootstrap-select.min.js" />
        <ScriptTag type="text/javascript" src="/assets/vendor/slick-carousel/slick/slick.js" />
        <ScriptTag type="text/javascript" src="/assets/vendor/gmaps/gmaps.min.js" />
        <ScriptTag type="text/javascript" src="/assets/vendor/ion-rangeslider/js/ion.rangeSlider.min.js" />

        <ScriptTag type="text/javascript" src="/assets/js/hs.core.js" />
        <ScriptTag type="text/javascript" src="/assets/js/components/hs.header.js" />
        <ScriptTag type="text/javascript" src="/assets/js/components/hs.unfold.js" />
        <ScriptTag type="text/javascript" src="/assets/js/components/hs.validation.js" />
        <ScriptTag type="text/javascript" src="/assets/js/components/hs.show-animation.js" />
        <ScriptTag type="text/javascript" src="/assets/js/components/hs.range-datepicker.js" />
        <ScriptTag type="text/javascript" src="/assets/js/components/hs.selectpicker.js" />
        <ScriptTag type="text/javascript" src="/assets/js/components/hs.range-slider.js" />
        <ScriptTag type="text/javascript" src="/assets/js/components/hs.go-to.js" />
        <ScriptTag type="text/javascript" src="/assets/js/components/hs.slick-carousel.js" />
        <ScriptTag type="text/javascript" src="/assets/js/components/hs.quantity-counter.js" />
        <ScriptTag type="text/javascript" src="/assets/js/components/hs.g-map.js" />

        <ScriptTag type="text/javascript" src="/assets/js/init.js" />
      </div>
    )
}

//        <ScriptTag type="text/javascript" src="../assets/vendor/jquery/dist/jquery.min.js" />
//        <ScriptTag type="text/javascript" src="../assets/vendor/bootstrap/bootstrap.min.js" />

//<ScriptTag type="text/javascript" src="/js/main.js" />


// <html lang="en">
// <head>
//   <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
//   <title>Travel Tome</title>
//   <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
// <head/>
// <html/>

import React from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';

export default function Menu() {

    const handleSignOut = () => {
        firebase.auth().signOut();
    }

    return (

      <header id="header" className="u-header u-header--dark-nav-links-xl u-header--show-hide-xl u-header--static-xl" data-header-fix-moment={500} data-header-fix-effect="slide">
        <div className="u-header__section u-header__shadow-on-show-hide py-4 py-xl-0">
          <div id="logoAndNav" className="container-fluid py-xl-2 border-bottom-xl">
            {/* Nav */}
            <nav className="js-mega-menu navbar navbar-expand-xl u-header__navbar u-header__navbar--no-space">
              {/* Logo */}
              <a className="navbar-brand u-header__navbar-brand-default u-header__navbar-brand-center u-header__navbar-brand-text-dark-xl" href="/" aria-label="MyTour">
                <img height="60px" src="/images/Icon.png" alt=""/>
                <span className="u-header__navbar-brand-text">Travel Tome</span>
              </a>
              {/* End Logo */}
              {/* Responsive Toggle Button */}
              <button type="button" className="navbar-toggler btn u-hamburger u-hamburger--primary order-2 ml-3" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navBar" data-toggle="collapse" data-target="#navBar">
                <span id="hamburgerTrigger" className="u-hamburger__box">
                  <span className="u-hamburger__inner" />
                </span>
              </button>
              {/* End Responsive Toggle Button */}
              {/* Navigation */}
              <div id="navBar" className="navbar-collapse u-header__navbar-collapse collapse order-2 order-xl-0 pt-4 p-xl-0 position-relative">
                <ul className="navbar-nav u-header__navbar-nav">
                  {/* Home */}
                  <li className="nav-item hs-has-sub-menu u-header__nav-item" data-event="hover" data-animation-in="slideInUp" data-animation-out="fadeOut">
                    <a id="homeMenu" className="nav-link u-header__nav-link u-header__nav-link-border" href="/">Home</a>
                  </li>
                  {/* Yacht */}
                  <li className="nav-item hs-has-sub-menu u-header__nav-item" data-event="hover" data-animation-in="slideInUp" data-animation-out="fadeOut">
                    <a id="yachtMenu" className="nav-link u-header__nav-link u-header__nav-link-border" href="/">Yachts</a>
                  </li>
                  {/* End Yacht */}
                  {/* Destination */}
                  <li className="nav-item hs-has-sub-menu u-header__nav-item" data-event="hover" data-animation-in="slideInUp" data-animation-out="fadeOut">
                    <a id="yachtMenu" className="nav-link u-header__nav-link u-header__nav-link-border" href="/">Destinations</a>
                  </li>
                  {/* End Destination */}

                  {/* About Us */}
                  <li className="nav-item hs-has-sub-menu u-header__nav-item" data-event="hover" data-animation-in="slideInUp" data-animation-out="fadeOut">
                    <a id="aboutMenu" className="nav-link u-header__nav-link u-header__nav-link-toggle u-header__nav-link-border" href="javascript:;" aria-haspopup="true" aria-expanded="false" aria-labelledby="aboutSubMenu">About Us</a>
                    {/* About Submenu */}
                    <ul id="aboutSubMenu" className="hs-sub-menu u-header__sub-menu u-header__sub-menu-rounded u-header__sub-menu-bordered hs-sub-menu-right u-header__sub-menu--spacer" aria-labelledby="aboutMenu" style={{minWidth: '230px'}}>
                      <li><a className="nav-link u-header__sub-menu-nav-link" href="../others/about.html">About us</a></li>
                      <li><a className="nav-link u-header__sub-menu-nav-link" href="../others/contact.html">Contact</a></li>
                      <li><a className="nav-link u-header__sub-menu-nav-link" href="../others/terms-conditions.html">Terms of Use</a></li>
                      <li><a className="nav-link u-header__sub-menu-nav-link" href="../others/faq.html">FAQs</a></li>
                    </ul>
                    {/* End About Submenu */}
                  </li>
                  {/* End About Us */}
                </ul>
              </div>
              {/* End Navigation */}
            </nav>
            {/* End Nav */}
          </div>
        </div>
      </header>
    )
}

// <div className="site-wrap">
//   <header className="site-navbar" role="banner">
//     <div className="container">
//       <div className="row align-items-center">
//         <div className="col-11 col-xl-2">
//           <h1 className="mb-0 site-logo"><a href="#" className="text-white h2 mb-0">Travel Tome</a></h1>
//         </div>
//         <div className="col-12 col-md-10 d-none d-xl-block">
//           <nav className="site-navigation position-relative text-right" role="navigation">
//             <ul className="site-menu js-clone-nav mr-auto d-none d-lg-block">
//               <li><Link to="/home"><span>Home</span></Link></li>
//               <li className="active"><Link to="/listing"><span>Listings</span></Link></li>
//             </ul>
//           </nav>
//         </div>
//       </div>
//     </div>
//   </header>
// </div>

// <li className="has-children">
//   <a href="https://preview.colorlib.com/theme/browse/about.html"><span>Dropdown</span></a>
//   <ul className="dropdown arrow-top">
//     <li><a href="https://preview.colorlib.com/theme/browse/listings.html#">Menu One</a></li>
//     <li><a href="https://preview.colorlib.com/theme/browse/listings.html#">Menu Two</a></li>
//     <li><a href="https://preview.colorlib.com/theme/browse/listings.html#">Menu Three</a></li>
//     <li className="has-children">
//       <a href="https://preview.colorlib.com/theme/browse/listings.html#">Dropdown</a>
//       <ul className="dropdown">
//         <li><a href="https://preview.colorlib.com/theme/browse/listings.html#">Menu One</a></li>
//         <li><a href="https://preview.colorlib.com/theme/browse/listings.html#">Menu Two</a></li>
//         <li><a href="https://preview.colorlib.com/theme/browse/listings.html#">Menu Three</a></li>
//         <li><a href="https://preview.colorlib.com/theme/browse/listings.html#">Menu Four</a></li>
//       </ul>
//     </li>
//   </ul>
// </li>

// <ul class="site-nav-wrap">
//     <li>
//         <Link to="/home">Home</Link>
//     </li>
//     <li>
//         <Link to="/listing">Listing</Link>
//     </li>
// </ul>

// <li>
//     <Link onClick={handleSignOut} to="/login">Login</Link>
// </li>

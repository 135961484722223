import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import $ from "jquery";
import "./services/firebase";
import "./font.css";

//import "flatpickr/dist/themes/material_green.css";

import "./srcassets/vendor/font-awesome/css/fontawesome-all.min.css"
import "./srcassets/vendor/animate.css/animate.min.css"
import "./srcassets/vendor/hs-megamenu/src/hs.megamenu.css"
import "./srcassets/vendor/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css"
import "./srcassets/vendor/fancybox/jquery.fancybox.css"
// import "./documentation/assets/vendor/jquery-ui/themes/base/jquery-ui.min.css"
// import "./documentation/assets/vendor/prism/prism.css"
import "./srcassets/vendor/slick-carousel/slick/slick.css"
import "./srcassets/vendor/flatpickr/dist/flatpickr.min.css"
import "./srcassets/vendor/bootstrap-select/dist/css/bootstrap-select.min.css"
import "./srcassets/vendor/dzsparallaxer/dzsparallaxer.css"
import "./srcassets/vendor/ion-rangeslider/css/ion.rangeSlider.css"

import "./global.css";


window.jQuery = window.$ = $;
require("bootstrap");

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

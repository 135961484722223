import React, { Component } from "react";
import YachtsDataService from "../../services/yachts.service";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAltH } from '@fortawesome/free-solid-svg-icons'
import { faBed } from '@fortawesome/free-solid-svg-icons'
import { faShower } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import Carousel from 'nuka-carousel';

var createReactClass = require('create-react-class')

export default class YachtDetails extends Component {

  constructor(props) {
    super(props);
    this.onDataChange = this.onDataChange.bind(this);

    this.state = {
      yachtId: props.yachtId,
      yacht: {images: [], navigationEquipment: [], comfortEquipment: [], entertainmentEquipment: [], obligatoryExtras: [], optionalExtras: []},
    };

    this.unsubscribe = undefined;
  }

  componentDidMount() {
    this.unsubscribe = YachtsDataService.getById(this.state.yachtId).onSnapshot(this.onDataChange);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onDataChange(item) {
    let yacht = item.data();

    let minPrice = yacht.prices[0].price;
    yacht.prices.forEach((price) => {if (price.price < minPrice) {minPrice = price.price}});

    yacht.minPrice = minPrice;

    if (!yacht.navigationEquipment) {
      yacht.navigationEquipment = [];
    }

    if (!yacht.comfortEquipment) {
      yacht.comfortEquipment = [];
    }

    if (!yacht.entertainmentEquipment) {
      yacht.entertainmentEquipment = [];
    }

    if (!yacht.obligatoryExtras) {
      yacht.obligatoryExtras = [];
    }

    if (!yacht.optionalExtras) {
      yacht.optionalExtras = [];
    }

    console.log(yacht);
    this.setState({
      yacht: yacht,
    });

  }

  render() {
    const { yacht } = this.state;

    return (
      <>
      <div className="col-lg-8 col-xl-9">
        <div className="d-block d-md-flex flex-center-between align-items-start mb-3">
          <div className="mb-3">
            <div className="d-block d-md-flex flex-horizontal-center mb-2 mb-md-0">
              <h4 className="font-size-23 font-weight-bold mb-1 mr-3 pr-1">{yacht.name} ({yacht.year})</h4>
              <div className="d-inline-flex align-items-center font-size-13 text-lh-1">
                <div className="green-lighter mr-2 letter-spacing-2">
                  <small className="fas fa-star" />
                  <small className="fas fa-star" />
                  <small className="fas fa-star" />
                  <small className="fas fa-star" />
                  <small className="fas fa-star" />
                </div>
                <span className="text-secondary font-size-14 mt-1">48 Reviews</span>
              </div>
            </div>
            <div className="d-block d-md-flex flex-horizontal-center font-size-14 text-gray-1">
              <i className="icon flaticon-placeholder mr-2 font-size-20" /> {yacht.homeBase}
              <a href="#" className="ml-1 d-block d-md-inline"> - View on map</a>
            </div>
          </div>
          <ul className="list-group list-group-borderless list-group-horizontal custom-social-share">
            <li className="list-group-item px-1">
              <a href="#" className="height-45 width-45 border rounded border-width-2 flex-content-center">
                <i className="flaticon-like font-size-18 text-dark" />
              </a>
            </li>
            <li className="list-group-item px-1">
              <a href="#" className="height-45 width-45 border rounded border-width-2 flex-content-center">
                <i className="flaticon-share font-size-18 text-dark" />
              </a>
            </li>
          </ul>
        </div>
        <div className="py-4 border-top mb-4">
          <ul className="mb-4 list-group list-group-borderless list-group-horizontal flex-center-between text-center mx-md-4 flex-wrap">
            <li className="list-group-item text-lh-sm ">
              <i className="flaticon-ruler text-primary font-size-40 mb-1 d-block" />
              <div className="text-gray-1">{yacht.length} m</div>
            </li>
            <li className="list-group-item text-lh-sm ">
              <i className="flaticon-user-2 text-primary font-size-40 mb-1 d-block" />
              <div className="text-gray-1">{yacht.berths}</div>
            </li>
            <li className="list-group-item text-lh-sm ">
              <i className="flaticon-door text-primary font-size-40 mb-1 d-block" />
              <div className="text-gray-1">{yacht.cabins}</div>
            </li>
            <li className="list-group-item text-lh-sm ">
              <i className="flaticon-bathtub text-primary font-size-40 mb-1 d-block" />
              <div className="text-gray-1">{yacht.wc}</div>
            </li>
          </ul>
          <div className="position-relative">
            {/* Images Carousel */}
            <Carousel heightMode={'first'} renderBottomCenterControls={false}
              renderCenterLeftControls={({ previousSlide }) => ( <div style={{padding: '10px'}}><FontAwesomeIcon icon={faChevronLeft} onClick={previousSlide} style={{color: "#fff", cursor: "pointer", fontSize: '2rem'}} /></div> )}
              renderCenterRightControls={({ nextSlide }) => ( <div style={{padding: '10px'}}><FontAwesomeIcon icon={faChevronRight} onClick={nextSlide} style={{color: "#fff", cursor: "pointer", fontSize: '2rem'}}/></div> )}
            >
              {yacht.images.map((image) => (
                  <img className="img-fluid border-radius-3" src={image.url} alt={image.description} />
              ))}
            </Carousel>
            {/* End Images Carousel */}
          </div>
        </div>
        <div id="stickyBlockStartPoint" className="mb-4">
          <div className="border rounded-pill js-sticky-block p-1 border-width-2 z-index-4 bg-white" data-parent="#stickyBlockStartPoint" data-offset-target="#logoAndNav" data-sticky-view="lg" data-start-point="#stickyBlockStartPoint" data-end-point="#stickyBlockEndPoint" data-offset-top={30} data-offset-bottom={30}>
            <ul className="js-scroll-nav nav tab-nav-pill flex-nowrap tab-nav">
              <li className="nav-item">
                <a className="nav-link font-weight-medium" href="#scroll-description">
                  <div className="d-flex flex-column flex-md-row  position-relative text-dark align-items-center">
                    <span className="tabtext font-weight-semi-bold">Description</span>
                  </div>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link font-weight-medium" href="#scroll-equipment">
                  <div className="d-flex flex-column flex-md-row  position-relative text-dark align-items-center">
                    <span className="tabtext font-weight-semi-bold">Equipment</span>
                  </div>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link font-weight-medium" href="#scroll-extras">
                  <div className="d-flex flex-column flex-md-row  position-relative text-dark align-items-center">
                    <span className="tabtext font-weight-semi-bold">Extras</span>
                  </div>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link font-weight-medium" href="#scroll-location">
                  <div className="d-flex flex-column flex-md-row  position-relative text-dark align-items-center">
                    <span className="tabtext font-weight-semi-bold">Location</span>
                  </div>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link font-weight-medium" href="#scroll-reviews">
                  <div className="d-flex flex-column flex-md-row  position-relative text-dark align-items-center">
                    <span className="tabtext font-weight-semi-bold">Reviews</span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="border-bottom position-relative">
          <h5 id="scroll-description" className="font-size-21 font-weight-bold text-dark">
            Description
          </h5>
          <ul className="list-group list-group-borderless list-group-horizontal list-group-flush no-gutters row">
            <li className="col-md-4 mb-5 list-group-item py-0">
              <div className="font-weight-bold text-dark">Kind</div>
              <span className="text-gray-1">{yacht.kind}</span>
            </li>
            <li className="col-md-4 mb-5 list-group-item py-0">
              <div className="font-weight-bold text-dark">Engine</div>
              <span className="text-gray-1">{yacht.engine}</span>
            </li>
            <li className="col-md-4 mb-5 list-group-item py-0">
              <div className="font-weight-bold text-dark">Beam</div>
              <span className="text-gray-1">{yacht.beam}</span>
            </li>
            <li className="col-md-4 mb-5 list-group-item py-0">
              <div className="font-weight-bold text-dark">Draught</div>
              <span className="text-gray-1">{yacht.draught}</span>
            </li>
            <li className="col-md-4 mb-5 list-group-item py-0">
              <div className="font-weight-bold text-dark">Displacement</div>
              <span className="text-gray-1">{yacht.displacement}</span>
            </li>
            <li className="col-md-4 mb-5 list-group-item py-0">
              <div className="font-weight-bold text-dark">Fuel Capacity</div>
              <span className="text-gray-1">{yacht.fuelCapacity}</span>
            </li>
            <li className="col-md-4 mb-5 list-group-item py-0">
              <div className="font-weight-bold text-dark">Water Capacity</div>
              <span className="text-gray-1">{yacht.waterCapacity}</span>
            </li>
            <li className="col-md-4 mb-5 list-group-item py-0">
              <div className="font-weight-bold text-dark">Mainsail Type</div>
              <span className="text-gray-1">{yacht.mainsailType}</span>
            </li>
            <li className="col-md-4 mb-5 list-group-item py-0">
              <div className="font-weight-bold text-dark">Genoa Type</div>
              <span className="text-gray-1">{yacht.genoaType}</span>
            </li>
          </ul>
        </div>
        <div className="border-bottom py-4">
          <h5 id="scroll-equipment" className="font-size-21 font-weight-bold text-dark mb-4">
            Equipment
          </h5>
          <h6>
            Navigation
          </h6>
          <ul className="list-group list-group-borderless list-group-horizontal list-group-flush no-gutters row">
            {yacht.navigationEquipment.map((equipmnet) => <li className="col-md-4 mb-3 list-group-item">{equipmnet}</li>)}
          </ul>
          <h6>
            Comfort
          </h6>
          <ul className="list-group list-group-borderless list-group-horizontal list-group-flush no-gutters row">
            {yacht.comfortEquipment.map((equipmnet) => <li className="col-md-4 mb-3 list-group-item">{equipmnet}</li>)}
          </ul>
          <h6>
            Entertainment
          </h6>
          <ul className="list-group list-group-borderless list-group-horizontal list-group-flush no-gutters row">
            {yacht.entertainmentEquipment.map((equipmnet) => <li className="col-md-4 mb-3 list-group-item">{equipmnet}</li>)}
          </ul>
        </div>
        <div className="border-bottom py-4">
          <h5 id="scroll-specifications" className="font-size-21 font-weight-bold text-dark mb-4">
            Extras
          </h5>
          <h6>
            Obligatory
          </h6>
          <ul className="list-group list-group-borderless list-group-horizontal list-group-flush no-gutters row">
            {yacht.obligatoryExtras.map((extra) =>
              <li className="col-md-6 mb-6 list-group-item py-0">
                <div className="font-weight-bold text-dark">{extra.description}</div><span className="text-gray-1">{extra.price} &euro; per {extra.period}</span>
              </li>
            )}
          </ul>
          <h6>
            Optional
          </h6>
          <ul className="list-group list-group-borderless list-group-horizontal list-group-flush no-gutters row">
            {yacht.optionalExtras.map((extra) =>
              <li className="col-md-6 mb-6 list-group-item py-0">
                <div className="font-weight-bold text-dark">{extra.description}</div><span className="text-gray-1">{extra.price} &euro; per {extra.period}</span>
              </li>
            )}
          </ul>
        </div>
        <div className="border-bottom py-4">
          <h5 id="scroll-location" className="font-size-21 font-weight-bold text-dark mb-4">
            Location
          </h5>
          <iframe src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d7772.225184901051!2d80.28441927545006!3d13.092050163095971!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e0!4m3!3m2!1d13.098645!2d80.2916092!4m5!1s0x3a526f5213f46501%3A0x56d2a4b14dba42f2!2sMadras%20High%20Court%2C%20High%20Ct%20Rd%2C%20Parry's%20Corner%2C%20George%20Town%2C%20Chennai%2C%20Tamil%20Nadu%20600108!3m2!1d13.0867057!2d80.28774949999999!5e0!3m2!1sen!2sin!4v1580358870925!5m2!1sen!2sin" width="100%" height={480} frameBorder={0} style={{border: 0}} allowFullScreen />
        </div>
        <div className="border-bottom py-4">
          <h5 id="scroll-reviews" className="font-size-21 font-weight-bold text-dark mb-4">
            Average Reviews
          </h5>
          <div className="row">
            <div className="col-md-4 mb-4 mb-md-0">
              <div className="border rounded flex-content-center py-5 border-width-2">
                <div className="text-center">
                  <h2 className="font-size-50 font-weight-bold text-primary mb-0 text-lh-sm">
                    4.2<span className="font-size-20">/5</span>
                  </h2>
                  <div className="font-size-25 text-dark mb-3">Very Good</div>
                  <div className="text-gray-1">From 40 reviews</div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-6 mb-4">
                  <h6 className="font-weight-normal text-gray-1 mb-1">
                    Cleanliness
                  </h6>
                  <div className="flex-horizontal-center mr-6">
                    <div className="progress bg-gray-33 rounded-pill w-100" style={{height: '7px'}}>
                      <div className="progress-bar rounded-pill" role="progressbar" style={{width: '80%'}} aria-valuenow={80} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <div className="ml-3 text-primary font-weight-bold">
                      4.8
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <h6 className="font-weight-normal text-gray-1 mb-1">
                    Facilities
                  </h6>
                  <div className="flex-horizontal-center mr-6">
                    <div className="progress bg-gray-33 rounded-pill w-100" style={{height: '7px'}}>
                      <div className="progress-bar rounded-pill" role="progressbar" style={{width: '40%'}} aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <div className="ml-3 text-primary font-weight-bold">
                      1.4
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <h6 className="font-weight-normal text-gray-1 mb-1">
                    Value for money
                  </h6>
                  <div className="flex-horizontal-center mr-6">
                    <div className="progress bg-gray-33 rounded-pill w-100" style={{height: '7px'}}>
                      <div className="progress-bar rounded-pill" role="progressbar" style={{width: '60%'}} aria-valuenow={60} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <div className="ml-3 text-primary font-weight-bold">
                      3.2
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <h6 className="font-weight-normal text-gray-1 mb-1">
                    Service
                  </h6>
                  <div className="flex-horizontal-center mr-6">
                    <div className="progress bg-gray-33 rounded-pill w-100" style={{height: '7px'}}>
                      <div className="progress-bar rounded-pill" role="progressbar" style={{width: '100%'}} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <div className="ml-3 text-primary font-weight-bold">
                      5.0
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <h6 className="font-weight-normal text-gray-1 mb-1">
                    Location
                  </h6>
                  <div className="flex-horizontal-center mr-6">
                    <div className="progress bg-gray-33 rounded-pill w-100" style={{height: '7px'}}>
                      <div className="progress-bar rounded-pill" role="progressbar" style={{width: '86%'}} aria-valuenow={86} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <div className="ml-3 text-primary font-weight-bold">
                      4.8
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="stickyBlockEndPoint" />
        <div className="border-bottom py-4">
          <h5 className="font-size-21 font-weight-bold text-dark mb-8">
            Showing 16 verified guest comments
          </h5>
          <div className="media flex-column flex-md-row align-items-center align-items-md-start mb-4">
            <div className="mr-md-5">
              <a className="d-block" href="#">
                <img className="img-fluid mb-3 mb-md-0 rounded-circle" src="../../assets/img/85x85/img1.jpg" alt="Image-Description" />
              </a>
            </div>
            <div className="media-body text-center text-md-left">
              <div className="mb-4">
                <h6 className="font-weight-bold text-gray-3">
                  <a href="#">Helena</a>
                </h6>
                <div className="font-weight-normal font-size-14 text-gray-9 mb-2">April 25, 2019 at 10:46 am</div>
                <div className="d-flex align-items-center flex-column flex-md-row mb-2">
                  <button type="button" className="btn btn-xs btn-primary rounded-xs font-size-14 py-1 px-2 mr-2 mb-2 mb-md-0">4.6 /5 </button>
                  <span className="font-weight-bold font-italic text-gray-3">The worst hotel ever ”</span>
                </div>
                <p className="text-lh-1dot6 mb-0 pr-lg-5">Morbi velit eros, sagittis in facilisis non, rhoncus et erat. Nam posuere ultricies tortor imperdiet vitae. Curabitur lacinia neque non metus</p>
              </div>
              <div className="review">
                <div className="row no-gutters justify-content-between align-items-center border border-color-8 border-width-2 rounded-xs p-3 px-md-3 py-md-2 pr-xl-5">
                  <div className="col">
                    <div className="font-weight-normal font-size-14 text-gray-1 mx-lg-1 my-lg-1 mb-3 mb-md-0">Was This Review...?</div>
                  </div>
                  <div className="col-md-6 col-lg-7 col-xl-4">
                    <div className="d-flex justify-content-between my-lg-1">
                      <a className="text-indigo-light" href="#">
                        <i className="flaticon-like-1 font-size-15 mr-1" />
                        <span className="font-size-14 font-weight-normal">Like</span>
                      </a>
                      <a className="text-gray-7" href="#">
                        <i className="flaticon-dislike font-size-15 mr-1" />
                        <span className="font-size-14 font-weight-normal">Dislike</span>
                      </a>
                      <a className="text-red-light-2" href="#">
                        <i className="flaticon-like font-size-15 mr-1" />
                        <span className="font-size-14 font-weight-normal">Love</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="media flex-column flex-md-row align-items-center align-items-md-start mb-0">
            <div className="mr-md-5">
              <a className="d-block" href="#">
                <img className="img-fluid mb-3 mb-md-0 rounded-circle" src="../../assets/img/85x85/img2.jpg" alt="Image-Description" />
              </a>
            </div>
            <div className="media-body text-center text-md-left">
              <div className="mb-4">
                <h6 className="font-weight-bold text-gray-3">
                  <a href="#">Ashleigh</a>
                </h6>
                <div className="font-weight-normal font-size-14 text-gray-9 mb-2">April 25, 2019 at 10:46 am</div>
                <div className="d-flex align-items-center flex-column flex-md-row mb-2">
                  <button type="button" className="btn btn-xs btn-primary rounded-xs font-size-14 py-1 px-2 mr-2 mb-2 mb-md-0">4.6 /5 </button>
                  <span className="font-weight-bold font-italic text-gray-3">Was too noisy and not suitable for business meetings.”</span>
                </div>
                <p className="text-lh-1dot6 pr-lg-5 mb-0">Morbi velit eros, sagittis in facilisis non, rhoncus et erat. Nam posuere ultricies tortor imperdiet vitae. Curabitur lacinia neque non metus</p>
              </div>
              <div className="review">
                <div className="row no-gutters justify-content-between align-items-center border border-color-8 border-width-2 rounded-xs p-3 px-md-3 py-md-2 pr-xl-5">
                  <div className="col">
                    <div className="font-weight-normal font-size-14 text-gray-1 mx-lg-1 my-lg-1 mb-3 mb-md-0">Was This Review...?</div>
                  </div>
                  <div className="col-md-6 col-lg-7 col-xl-4">
                    <div className="d-flex justify-content-between my-lg-1">
                      <a className="text-indigo-light" href="#">
                        <i className="flaticon-like-1 font-size-15 mr-1" />
                        <span className="font-size-14 font-weight-normal">Like</span>
                      </a>
                      <a className="text-gray-7" href="#">
                        <i className="flaticon-dislike font-size-15 mr-1" />
                        <span className="font-size-14 font-weight-normal">Dislike</span>
                      </a>
                      <a className="text-red-light-2" href="#">
                        <i className="flaticon-like font-size-15 mr-1" />
                        <span className="font-size-14 font-weight-normal">Love</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-4">
          <h5 className="font-size-21 font-weight-bold text-dark mb-6">
            Write a Review
          </h5>
          <div className="row">
            <div className="col-md-4 mb-6">
              <h6 className="font-weight-bold text-dark mb-1">
                Cleanliness
              </h6>
              <span className="text-primary font-size-20 letter-spacing-3">
                <small className="far fa-smile" />
                <small className="far fa-smile" />
                <small className="far fa-smile" />
                <small className="far fa-smile text-muted" />
                <small className="far fa-smile text-muted" />
              </span>
            </div>
            <div className="col-md-4 mb-6">
              <h6 className="font-weight-bold text-dark mb-1">
                Facilities
              </h6>
              <span className="text-primary font-size-20 letter-spacing-3">
                <small className="far fa-smile" />
                <small className="far fa-smile" />
                <small className="far fa-smile" />
                <small className="far fa-smile text-muted" />
                <small className="far fa-smile text-muted" />
              </span>
            </div>
            <div className="col-md-4 mb-6">
              <h6 className="font-weight-bold text-dark mb-1">
                Value for money
              </h6>
              <span className="text-primary font-size-20 letter-spacing-3">
                <small className="far fa-smile" />
                <small className="far fa-smile" />
                <small className="far fa-smile" />
                <small className="far fa-smile" />
                <small className="far fa-smile" />
              </span>
            </div>
            <div className="col-md-4 mb-6">
              <h6 className="font-weight-bold text-dark mb-1">
                Service
              </h6>
              <span className="text-primary font-size-20 letter-spacing-3">
                <small className="far fa-smile" />
                <small className="far fa-smile" />
                <small className="far fa-smile" />
                <small className="far fa-smile text-muted" />
                <small className="far fa-smile text-muted" />
              </span>
            </div>
            <div className="col-md-4 mb-6">
              <h6 className="font-weight-bold text-dark mb-1">
                Location
              </h6>
              <span className="text-primary font-size-20 letter-spacing-3">
                <small className="far fa-smile" />
                <small className="far fa-smile" />
                <small className="far fa-smile" />
                <small className="far fa-smile text-muted" />
                <small className="far fa-smile text-muted" />
              </span>
            </div>
          </div>
          <form className="js-validate" noValidate="novalidate">
            <div className="row mb-5 mb-lg-0">
              {/* Input */}
              <div className="col-sm-6 mb-5">
                <div className="js-form-message">
                  <input type="text" className="form-control" name="name" placeholder="Name" aria-label="Jack Wayley" required data-error-class="u-has-error" data-msg="Please enter your name." data-success-class="u-has-success" />
                </div>
              </div>
              {/* End Input */}
              {/* Input */}
              <div className="col-sm-6 mb-5">
                <div className="js-form-message">
                  <input type="email" className="form-control" name="email" placeholder="Email" aria-label="jackwayley@gmail.com" required data-msg="Please enter a valid email address." data-error-class="u-has-error" data-success-class="u-has-success" />
                </div>
              </div>
              {/* End Input */}
              <div className="col-sm-12 mb-5">
                <div className="js-form-message">
                  <div className="input-group">
                    <textarea className="form-control" rows={6} cols={77} name="text" placeholder="Comment" aria-label="Hi there, I would like to ..." required data-msg="Please enter a reason." data-error-class="u-has-error" data-success-class="u-has-success" defaultValue={""} />
                  </div>
                </div>
              </div>
              {/* End Input */}
              <div className="col d-flex justify-content-center justify-content-lg-start">
                <button type="submit" className="btn rounded-xs bg-blue-dark-1 text-white p-2 height-51 width-190 transition-3d-hover">Submit Review</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="col-lg-4 col-xl-3">
        <div className="mb-4">
          <div className="border border-color-7 rounded mb-5">
            <div className="border-bottom">
              <div className="p-4">
                <span className="font-size-14">From</span>
                <span className="font-size-24 text-gray-6 font-weight-bold ml-1">{yacht.minPrice} &euro;</span>
              </div>
            </div>
            <div className="p-4 m-1">
              {/* Input */}
              <span className="d-block text-gray-1 font-weight-normal mb-0 text-left">From - To</span>
              <div className="mb-4">
                <div className="border-bottom border-width-2 border-color-1">
                  <div id="datepickerWrapperPick" className="u-datepicker input-group">
                    <div className="input-group-prepend">
                      <span className="d-flex align-items-center mr-2 font-size-21">
                        <i className="flaticon-calendar text-primary font-weight-semi-bold" />
                      </span>
                    </div>
                    <input className="js-range-datepicker w-auto font-size-16 ml-1 shadow-none font-weight-bold form-control hero-form bg-transparent border-0 flatpickr-input p-0" type="text" placeholder="July 7/2019" aria-label="July 7/2019" data-rp-wrapper="#datepickerWrapperPick" data-rp-type="range" data-rp-date-format="M d / Y" data-rp-default-date="[&quot;Jul 7 / 2020&quot;, &quot;Aug 25 / 2020&quot;]" />
                  </div>
                  {/* End Datepicker */}
                </div>
              </div>
              {/* End Input */}
              <div className="text-center">
                <a href="../yacht/yacht-booking.html" className="btn btn-primary d-flex align-items-center justify-content-center height-60 w-100 mb-xl-0 mb-lg-1 transition-3d-hover font-weight-bold">Book Now</a>
              </div>
            </div>
          </div>
          <div className="border border-color-7 rounded p-4 mb-5">
            <h6 className="font-size-17 font-weight-bold text-gray-3 mx-1 mb-3 pb-1">Why Book With Us?</h6>
            <div className="d-flex align-items-center mb-3">
              <i className="flaticon-star font-size-25 text-primary mr-3 pr-1" />
              <h6 className="mb-0 font-size-14 text-gray-1">
                <a href="#">No-hassle best price guarantee</a>
              </h6>
            </div>
            <div className="d-flex align-items-center mb-3">
              <i className="flaticon-support font-size-25 text-primary mr-3 pr-1" />
              <h6 className="mb-0 font-size-14 text-gray-1">
                <a href="#">Customer care available 24/7</a>
              </h6>
            </div>
            <div className="d-flex align-items-center mb-3">
              <i className="flaticon-favorites-button font-size-25 text-primary mr-3 pr-1" />
              <h6 className="mb-0 font-size-14 text-gray-1">
                <a href="#">Hand-picked Tours &amp; Activities</a>
              </h6>
            </div>
            <div className="d-flex align-items-center mb-0">
              <i className="flaticon-airplane font-size-25 text-primary mr-3 pr-1" />
              <h6 className="mb-0 font-size-14 text-gray-1">
                <a href="#">Free Travel Insureance</a>
              </h6>
            </div>
          </div>
        </div>
      </div>

      </>
    );
  }
}


      // <>
      //   <div className="d-block d-md-flex listing-horizontal" key={yacht.id}>
      //     <Carousel heightMode={'first'} renderBottomCenterControls={false}
      //       renderCenterLeftControls={({ previousSlide }) => ( <div style={{padding: '10px'}}><FontAwesomeIcon icon={faChevronLeft} onClick={previousSlide} style={{cursor: "pointer", fontSize: '2rem'}} /></div> )}
      //       renderCenterRightControls={({ nextSlide }) => ( <div style={{padding: '10px'}}><FontAwesomeIcon icon={faChevronRight} onClick={nextSlide} style={{cursor: "pointer", fontSize: '2rem'}}/></div> )}
      //     >
      //       {yacht.images.map((image) => (<img src={image.url}/>))}
      //     </Carousel>
      //     <div className="lh-content">
      //       <h3><a href="#">{yacht.name} ({yacht.year})</a></h3>
      //       <p>{yacht.model}</p>
      //       <p><FontAwesomeIcon icon={faArrowsAltH} /> {yacht.length} m</p>
      //       <p><FontAwesomeIcon icon={faBed} /> {yacht.berths}&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faShower} /> {yacht.wc}</p>
      //       <input type="submit" className="btn text-white btn-primary" value="Book" style={{borderRadius: '30px', padding: '10px 30px'}}/>
      //     </div>
      //   </div>
      // </>

// <Carousel>
//   {yacht.images.map((image) => (<img src={image}/>))}
// </Carousel>

// renderCenterLeftControls={({ previousSlide }) => (
//   <button onClick={previousSlide}>
//     <FontAwesomeIcon icon={faChevronCircleLeft} />
//   </button>
// )}
// renderCenterRightControls={({ nextSlide }) => (
//   <button onClick={nextSlide}>
//     <FontAwesomeIcon icon={faChevronCircleRight} />
//   </button>
// )}


// <div className="list row">
//   <div className="col-md-6">
//     <ul className="list-group">
//       {tutorials &&
//         tutorials.map((tutorial, index) => (
//           <li
//             className={ "list-group-item " + (index === currentIndex ? "active" : "") }
//             onClick={() => this.setActiveTutorial(tutorial, index)}
//             key={index}
//           >
//             {tutorial.title}
//           </li>
//         ))}
//     </ul>
//   </div>
// </div>


// <div className="col-md-6">
//   {currentTutorial ? (
//     <Tutorial
//       tutorial={currentTutorial}
//       refreshList={this.refreshList}
//     />
//   ) : (
//     <div>
//       <br />
//       <p>Please click on a Tutorial...</p>
//     </div>
//   )}
// </div>

import React from "react";
import Dashboard from './Dashboard/Dashboard';
import Home from './Home/Home';
import Listing from './Listing/Listing';
import Yacht from './Yacht/Yacht';
import { Route, Switch } from "react-router-dom";

const Routes = () => (
    <Switch>
        <Route path="/" exact component={Home}/>
        <Route path="/yachts" exact component={Listing}/>
        <Route path="/yacht/:yachtId" exact component={Yacht} />)}/>
    </Switch>
);

export default Routes;
